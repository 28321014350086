<template lang="pug">
nav#navbar.w-full.fixed.top-0.z-30
  .px-6.py-4.flex.items-center.justify-between.bg-aspire-blue-2(
    class="lg:flex-no-wrap xl:px-12",
    :class="{ scrolled: !view.atTopOfPage }"
  )
    router-link(:to="{ name: 'home' }")
      SvgIcon.-ml-12(icon="aspire", :classes="['w-64', 'h-12']")
    ul.inline-flex(v-if="breakpoints.isLgAndUp")
      li.nav-item(v-show="!['blog', 'post'].includes($route.name)")
        a.cool-link(
          v-scroll-to="{ el: '#college-admission-wrap', offset: -70 }"
        )
          | {{ $t('navbar.items.services') }}
      li.nav-item(v-show="!isBlog")
        a.ml-3.cool-link(
          v-scroll-to="{ el: '#knowledgebase-wrap', offset: -70 }"
        )
          | {{ $t('navbar.items.knowledgebase') }}
      li.nav-item(v-show="!isBlog")
        a.ml-3.cool-link(v-scroll-to="{ el:'#about-wrap', offset: -70  }")
          | {{ $t('navbar.items.about') }}
      li.nav-item(v-show="!isBlog")
        a.ml-3.cool-link(v-scroll-to="{ el: '#contact-wrap', offset: -70 }")
          | {{ $t('navbar.items.contact') }}
      li.nav-item(v-show="isBlog")
        router-link.ml-2.cool-link(:to="{ name: 'home' }")
          | {{ $t('navbar.items.home') }}
      li.nav-item
        router-link.ml-3.mr-1.cool-link(:to="{ name: 'blog' }")
          | {{ $t('navbar.items.blog') }}
      Translation.ml-2(:langs="langs")
    .text-aspire-yellow-1.outline-none(
      v-if="breakpoints.isLgAndDown",
      role="button",
      @click="toggleNavbar()"
    )
      SvgIcon(
        v-show="!menuShow",
        icon="hamburger",
        :classes="['w-6', 'h-6', 'focus:outline-none']"
      )
      SvgIcon(v-show="menuShow", icon="close", :classes="['w-4', 'h-4']")
        v-scroll-to= "{ el: '#about-wrap', offset: -30 }"
  transition(name="slide")
    .overflow-hidden.origin-top.transition-transform.duration-300.ease-in-out.bg-aspire-blue-2.px-3(
      v-show="breakpoints.isLgAndDown && menuShow"
    )
      Translation.ml-3(:langs="langs", @change="toggleNavbar()")
      ul.flex.flex-col.list.py-4
        li.nav-item.mt-2(v-show="!isBlog")
          a.ml-3.cool-link(
            v-scroll-to="{ el: '#college-admission-wrap', offset: -70 }",
            @click="toggleNavbar()"
          )
            | {{ $t('navbar.items.services') }}
        li.nav-item.mt-6(v-show="!isBlog")
          a.ml-3.cool-link(
            v-scroll-to="{ el: '#knowledgebase-wrap', offset: -70 }",
            @click="toggleNavbar()"
          )
            | {{ $t('navbar.items.knowledgebase') }}
        li.nav-item.mt-6(v-show="!isBlog")
          a.ml-3.cool-link(
            v-scroll-to="{ el:'#about-wrap', offset: -70  }",
            @click="toggleNavbar()"
          )
            | {{ $t('navbar.items.about') }}
        li.nav-item.mt-6(v-show="!isBlog")
          a.ml-3.cool-link(
            v-scroll-to="{ el: '#contact-wrap', offset: -70 }",
            @click="toggleNavbar()"
          )
            | {{ $t('navbar.items.contact') }}
        li.nav-item.mt-2(v-show="isBlog")
          router-link.ml-3.cool-link(
            :to="{ name: 'home' }",
            @click.native="toggleNavbar()"
          )
            | {{ $t('navbar.items.home') }}
        li.nav-item.mt-6
          router-link.ml-3.cool-link(
            :to="{ name: 'blog' }",
            @click.native="toggleNavbar()"
          )
            | {{ $t('navbar.items.blog') }}
</template>

<script>
// Components
import SvgIcon from "@/components/SvgIcon.vue";
import Translation from "@/components/Translation.vue";

// Mixins
import Breakpoints from "@/mixins/breakpoints";

export default {
  components: {
    SvgIcon,
    Translation,
  },
  mixins: [Breakpoints],
  data() {
    return {
      title: process.env.VUE_APP_NAME,
      menuShow: false,
      view: {
        atTopOfPage: true,
      },
    };
  },
  computed: {
    langs() {
      return [
        {
          name: this.$t("lang.english"),
          locale: "en",
        },
        {
          name: this.$t("lang.spanish"),
          locale: "es",
        },
      ];
    },
    isBlog() {
      return ["blog", "post"].includes(this.$route.name);
    },
  },
  methods: {
    handleScroll() {
      // when the user scrolls, check the pageYOffset
      if (window.pageYOffset > 0) {
        // user is scrolled
        if (this.view.atTopOfPage) this.view.atTopOfPage = false;
      } else {
        // user is at top of page
        if (!this.view.atTopOfPage) this.view.atTopOfPage = true;
      }
    },
    toggleNavbar() {
      this.menuShow = !this.menuShow;
    },
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped>
.scrolled {
  @apply shadow-xl border-b-0;
}

.nav-item {
  @apply flex items-center cursor-pointer;
}

.nav-item > a {
  @apply inline-block no-underline font-medium leading-snug text-sm text-white transition duration-500 ease-in-out;

  &:hover {
    @apply text-aspire-yellow-1;
  }
}

.cool-link::after {
  @apply bg-aspire-yellow-1;

  content: "";
  display: block;
  width: 0;
  transition: width 0.5s;
}

.cool-link:hover::after {
  width: 100%;
  transition: width 0.5s;
}

.slide-enter,
.slide-leave-to {
  transform: scaleY(0);
}
</style>
