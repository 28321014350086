<template lang="pug">
.relative.inline-flex(class="focus:outline-none", v-click-outside="close")
  slot(:open="open")
  transition(name="slide-fade")
    ul.w-full.z-10.rounded-md.bg-white.border.text-gray-700.top-0.left-0(
      v-show="showDropdown",
      ref="menu",
      :class="menuClasses"
    )
      li(v-for="(item, idx) in menu", :key="idx")
        a.rounded-lg.capitalize.py-3.px-8.block.whitespace-no-wrap.cursor-pointer(
          class="hover:bg-gray-200",
          @click="selected(item)"
        )
          | {{ item[trackBy] }}
</template>

<script>
export default {
  props: {
    menu: {
      type: Array,
      default: () => {},
    },
    trackBy: {
      type: String,
      default: "name",
    },
    align: {
      type: String,
      default: "right",
    },
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    menuClasses() {
      const classes = [];
      this.fixed ? classes.push("fixed") : classes.push("absolute");
      this.align === "left" ? classes.push("right-0") : classes.push("none");
      return classes;
    },
  },
  methods: {
    selected(item) {
      this.$emit("selected", item);
      this.showDropdown = false;
    },
    open() {
      this.showDropdown = !this.showDropdown;
    },
    close() {
      this.showDropdown = false;
    },
  },
};
</script>

<style scoped>
/* Enter and leave animations can use different */

/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.1s ease;
}

.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}
</style>
