<template lang="pug">
.fixed.top-0.w-full.z-50(class="sm:right-0 sm:mt-4 sm:mr-4 sm:w-400-px")
  transition(
    v-for="(toast, idx) in toasts",
    :key="`toast-${idx}`",
    name="slide-fade"
  )
    .relative.px-6.py-4.mb-4.shadow-lg(
      v-if="toast.alertOpen",
      class="sm:rounded",
      :class="styleToast(toast.type)",
      role="alert"
    )
      p.inline-block.align-middle.mr-8.font-bold.break-words
        | {{ toast.title }}
      p.mt-2.opacity-75.mr-8.break-words
        | {{ toast.message }}
      button.absolute.bg-transparent.text-2xl.font-semibold.leading-none.right-0.top-0.mt-4.mr-6.outline-none(
        class="focus:outline-none",
        @click="toast.alertOpen = false"
      )
        span ×
</template>

<script>
// Store
import state from "./store";

export default {
  computed: {
    toasts() {
      return state.toasts;
    },
  },
  methods: {
    styleToast(type) {
      return {
        "error-toast": type === "error",
        "warning-toast": type === "warning",
        "success-toast": type === "success",
        "info-toast": type === "info",
      };
    },
  },
};
</script>

<style scoped>
.error-toast {
  @apply bg-red-100 border-l-4 border-red-500 text-red-900;
}

.warning-toast {
  @apply bg-orange-100 border-l-4 border-orange-500 text-orange-900;
}

.success-toast {
  @apply bg-green-100 border-l-4 border-green-500 text-green-900;
}

.info-toast {
  @apply bg-blue-100 border-l-4 border-blue-500 text-blue-900;
}

/* Enter and leave animations can use different */

/* durations and timing functions.              */

.slide-fade-enter-active {
  transition: all 0.5s ease;
}

.slide-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>
