<template lang="pug">
Dropdown(v-slot="{ open }", :menu="langs", @selected="changeLocale")
  .inline-flex.justify-between.items-center.space-x-2.bg-transparent.text-white.font-semibold.py-2.px-4.border.border-white.rounded(
    role="button",
    class="hover:border-aspire-yellow-4 hover:text-aspire-yellow-4",
    @click="open()"
  )
    SvgIcon(icon="world")
    span.pr-2.text-sm
      | {{ localeName }}
    SvgIcon(icon="drop-down", :classes="['w-3', 'h-3']")
</template>

<script>
// Components
import SvgIcon from "@/components/SvgIcon.vue";
import Dropdown from "@/components/Dropdown/BaseDropdown.vue";

export default {
  components: {
    SvgIcon,
    Dropdown,
  },
  props: {
    langs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    localeName() {
      return this.langs.find((lang) => lang.locale === this.$i18n.locale).name;
    },
  },
  methods: {
    changeLocale(lang) {
      this.$i18n.locale = lang.locale;
      this.$emit("change");
    },
  },
};
</script>

<style scoped></style>
