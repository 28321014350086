<template lang="pug">
svg.fill-current.cursor-pointer(ref="icon", :class="classes", @click="click")
  use(:href="path")
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    classes: {
      Type: Array,
      default: () => ["w-5", "h-5"],
    },
  },
  computed: {
    path() {
      return `${require("@/assets/img/icons.svg")}#${this.icon}`;
    },
  },
  methods: {
    click(event) {
      this.$emit("click-icon", event.target);
    },
  },
};
</script>
