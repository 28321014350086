import Vue from "vue";
// import { DirectiveOptions } from "vue";

const HANDLER = "click_outside_handler";

const directive = {
  bind: function (el, binding, vnode) {
    const vm = vnode.context;
    const callback = binding.value;

    let initialMacrotaskEnded = false;
    window.setTimeout(function () {
      initialMacrotaskEnded = true;
    }, 0);

    el[HANDLER] = function (event) {
      // @NOTE: this test used to be just `el.contains`, but working with path is better,
      //        because it tests whether the element was there at the time of
      //        the click, not whether it is there now, that the event has arrived
      //        to the top.
      // @NOTE: `.path` is non-standard, the standard way is `.composedPath()`
      const path =
        event.path || (event.composedPath ? event.composedPath() : undefined);
      if (
        initialMacrotaskEnded &&
        (path ? path.indexOf(el) < 0 : !el.contains(event.target))
      ) {
        return callback.call(vm, event);
      }
    };

    document.documentElement.addEventListener("click", el[HANDLER], false);
  },
  unbind: function (el) {
    document.documentElement.removeEventListener("click", el[HANDLER], false);
    delete el[HANDLER];
  },
};

Vue.directive("click-outside", directive);
