import Vue from "vue";
import VueRouter from "vue-router";

// Views
const Home = () => import("@/views/Home.vue");
const Blog = () => import("@/views/Blog.vue");
const Post = () => import("@/views/Post.vue");
const NotFound = () => import("@/views/404.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/blog",
    name: "blog",
    component: Blog,
  },
  {
    path: "/post/:id",
    name: "post",
    component: Post,
    props: ({ params }) => ({
      id: Number.parseInt(params.id, 10) || 0,
    }),
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
