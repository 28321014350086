import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// plugins
import "./plugins/scrollTo";
import "./plugins/vueMeta";
import i18n from "./i18n";

// directives
import "./directives/clickOutside";

// Style
import "./assets/css/app.css";

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  el: "#app",
  render: (h) => h(App),
  mounted() {
    // You'll need this for renderAfterDocumentEvent.
    document.dispatchEvent(new Event("render-event"));
  },
});
