import Vue from "vue";

const state = Vue.observable({ toasts: [] });

/**
 *
 *
 * @param {number} ms
 * @memberof Util
 */
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export /**
 *
 * Show toast
 *
 * @param {string} type
 * @param {string} message
 * @param {number} [duration=5000]
 */
const showToast = (type, title, message, duration = 5000) => {
  const toast = {
    type,
    title,
    message,
    alertOpen: true,
  };
  state.toasts.push(toast);

  sleep(duration).then(() => {
    toast.alertOpen = false;
    if (!state.toasts.some((toast) => toast.alertOpen)) state.toasts = [];
  });
};

export default state;
