import state, { handleResize } from "@/mixins/breakpoints/store";

export default {
  computed: {
    breakpoints() {
      return state.breakpoints;
    },
  },
  created() {
    handleResize();
    window.addEventListener("resize", this.debounce(200));
  },
  methods: {
    destroyed() {
      window.removeEventListener("resize", this.debounce(200));
    },
    debounce(delay) {
      let timeoutID = null;
      return () => {
        clearTimeout(timeoutID);
        timeoutID = window.setTimeout(() => {
          handleResize();
        }, delay);
      };
    },
  },
};
