<template lang="pug">
#app
  Toast
  Navbar(v-if="!isNotFound")
  #content(
    :style="[isNotFound ? { 'margin-top': 0 } : { 'margin-top': '80px' }]"
  )
    router-view
  Footer.footer(v-if="!isNotFound")
</template>

<script>
// Components
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import Toast from "@/components/Toast/Toast.vue";

export default {
  components: {
    Navbar,
    Footer,
    Toast,
  },
  computed: {
    isNotFound() {
      return this.$route.name === "notFound";
    },
  },
};
</script>

<style scoped>
#app {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
}

.footer {
  grid-row-start: 2;
  grid-row-end: 3;
}
</style>
