<template lang="pug">
#footer.w-full.bg-aspire-blue-2.mt-16
  .w-full.inline-flex.justify-center.items-center.py-5.text-white.bg-aspire-blue-1
    a.flex.justify-center.items-center.btn-social-network(
      role="button",
      :href="links.facebook",
      target="_blank"
    )
      SvgIcon(
        icon="facebook",
        class="hover:text-aspire-yellow-1",
        :classes="['w-6', 'h-6']"
      )
    a.flex.justify-center.items-center.btn-social-network(
      role="button",
      :href="links.youtube",
      target="_blank",
      style="margin-left: 17px;"
    )
      SvgIcon(
        icon="youtube",
        class="hover:text-aspire-yellow-1",
        :classes="['w-6', 'h-6']"
      )
    a.flex.justify-center.items-center.btn-social-network.ml-6(
      role="button",
      :href="links.instagram",
      target="_blank"
    )
      SvgIcon(
        icon="instagram",
        class="hover:text-aspire-yellow-1",
        :classes="['w-6', 'h-6']"
      )
  .footer-content(class="sm:container sm:mx-auto sm:grid-cols-2 md:grid-cols-3")
    .section-1
      div
        span.title(class="md:text-left")
          | {{ $t('footer.company.name') }}
        hr.underline
      p.pt-4.text-white.text-sm
        | {{ $t('footer.company.description') }}
    .section-2
      span.title
        | {{ $t('footer.services') }}
      hr.underline
      ul
        li
          a.cursor-pointer(
            class="hover:underline",
            v-scroll-to="{ el: '#service-1', offset: -90 }"
          )
            | {{ $t('footer.service.initialConsultation') }}
        li
          a.cursor-pointer(
            class="hover:underline",
            v-scroll-to="{ el: '#service-2', offset: -90 }"
          )
            | {{ $t('footer.service.collegeCounseling') }}
        li
          a.cursor-pointer(
            class="hover:underline",
            v-scroll-to="{ el: '#service-3', offset: -90 }"
          )
            | {{ $t('footer.service.studySkills') }}
    .section-3
      span.title
        | {{ $t('footer.contact') }}
      hr.underline
      ul
        li
          SvgIcon(icon="email", :classes="['w-3', 'h-3']")
          span
            | {{ $t('footer.email') }}
        li
          SvgIcon(icon="phone", :classes="['w-3', 'h-3']")
          span
            | {{ $t('footer.phone') }}
  .pb-4
    hr.border-0.bg-gray-600.mb-4.mx-20(style="height: 0.5px;")
    p.text-xs.text-center.text-white
      | {{ $t('footer.copyright') }}
</template>

<script>
// Components
import SvgIcon from "@/components/SvgIcon.vue";

export default {
  components: {
    SvgIcon,
  },
  data() {
    return {
      links: {
        facebook: process.env.VUE_APP_FACEBOOK_LINK,
        youtube: process.env.VUE_APP_YOUTUBE_LINK,
        instagram: process.env.VUE_APP_INSTAGRAM_LINK,
      },
    };
  },
};
</script>

<style scoped>
hr.underline {
  @apply mt-1 bg-aspire-yellow-1 w-16 border-0;

  height: 2px;
}

ul {
  @apply list-none;

  & > li {
    @apply mt-4 text-white text-sm;
  }
}

.title {
  @apply block uppercase text-white font-bold text-base;
}

.footer-content {
  @apply grid gap-8 px-10 py-10;
}

.section-1 {
  & > p {
    max-width: 300px;
  }
}

.section-3 > ul > li {
  @apply flex items-center;

  & > span {
    @apply ml-2;
  }
}

@screen md {
  .section-2 {
    @apply justify-self-center;
  }

  .section-3 {
    @apply justify-self-end;
  }
}
</style>
