import Vue from "vue";

const screens = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

const breakpoints = {
  w: 0,
  h: 0,
  is: "",
  isSmAndUp: false,
  isSmAndDown: false,
  isMdAndUp: false,
  isMdAndDown: false,
  isLgAndUp: false,
  isLgAndDown: false,
};

const state = Vue.observable({ screens, breakpoints });

export function handleResize() {
  state.breakpoints.w = window.innerWidth;
  state.breakpoints.h = window.innerHeight;
  state.breakpoints.is = getBreakpoint(window.innerWidth);
  state.breakpoints.isSmAndUp = smAndUp(window.innerWidth);
  state.breakpoints.isSmAndDown = smAndDown(window.innerWidth);
  state.breakpoints.isMdAndUp = mdAndUp(window.innerWidth);
  state.breakpoints.isMdAndDown = mdAndDown(window.innerWidth);
  state.breakpoints.isLgAndUp = lgAndUp(window.innerWidth);
  state.breakpoints.isLgAndDown = lgAndDown(window.innerWidth);
}

function sm(val) {
  return val >= state.screens.sm && val <= state.screens.md;
}

function smAndUp(val) {
  return val >= state.screens.sm;
}

function smAndDown(val) {
  return val < state.screens.sm;
}

function md(val) {
  return val >= state.screens.sm && val <= state.screens.lg;
}

function mdAndUp(val) {
  return val >= state.screens.md;
}

function mdAndDown(val) {
  return val < state.screens.md;
}

function lg(val) {
  return val >= state.screens.sm && val <= state.screens.xl;
}

function lgAndUp(val) {
  return val >= state.screens.lg;
}

function lgAndDown(val) {
  return val < state.screens.lg;
}

function xl(val) {
  return val >= state.screens.xl;
}

function getBreakpoint(w) {
  if (sm(w)) return "sm";
  if (md(w)) return "md";
  if (lg(w)) return "lg";
  if (xl(w)) return "xl";
  return "all";
}

export default state;
